

#musicPageTemplate {
    width: 100%;
    height: 900px;
    margin-top: -5px;
  }
  
  .contentmusicPageTemplate {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 300ms;
    box-shadow: 0px 0px 10px 1px #000000ee;
    border-radius: 5px;
  }
  
 .backcontentmusicPageTemplate {
    background-color: #18171C;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .backcontentmusicPageTemplate {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .backcontentmusicPageTemplate::before {
    position: absolute;
    content: ' ';
    display: block;
    width: 100%;
    height: 160%;
    background: linear-gradient(90deg, transparent,  #EED476, #AD906E, #A55B03, transparent);
    animation: rotation_481 7000ms infinite linear;
  }
  
  .Dircontent {
    position: absolute;
    width: 99%;
    height: 99%;
    background-color: #18171C;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    display: flex;
    align-items: space-between;
  }
  
  @keyframes rotation_481 {
    0% {
      transform: rotateZ(0deg);
    }
  
    0% {
      transform: rotateZ(360deg);
    }
  }

  #albumDistribution{
    width: 80%;
    height: 100%;
    display: flex;
    margin: 3% 0;
  }
  #albumInfo{
    width: 30%;
    border-right: 1px solid #AD906E;
    margin: 1%;
    padding: 2%;
    border-bottom: 1px solid #AD906E;
    box-shadow: 10px 10px 10px -10px #EED476;
  }
  #albumInfo h1{
    border-bottom: 1px solid rgba(161, 161, 161,0.3);
    padding-bottom: 2%;
  }
  #musicPageMedia{
    display: flex;
    width: 150px;
    justify-content: space-evenly;
  }

  #musicPageMedia a{
    font-size: 1.2rem;
  }
  #coverPagealbum img {
    width: 300px;
    height: 300px;
  }
  #coverPagealbum{
    width: 100%;
    height: 300px;
  }
  #albumDisplay{
    width: 70%;
    margin: 2% 1%;
  }
  #ytVideoMusicPage{
    width: 100%;
  }
  #ytVideoMusicPage p{
    width: 100%;
    font-size: 1rem;
    border-bottom: 1px solid #AD906E;
    padding: 1%;
  }
  #ytVideoMusicPage p:hover{
    width: 100%;
    font-size: 1.05rem;
    color: #AD906E;
    transition: 10ms ease-in-out;
  }
  #ytVideoMusicPage p *:hover{
    color: #AD906E;
  }

  @media only screen and (max-width: 1400px){
    #musicPageTemplate {
        width: 100%;
        height: 1300px;
        margin-top: -5px;
      }
    #albumDistribution{
        width: 80%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 3% 0;
      }
      #albumInfo{
        width: 100%;
        max-height: 700px;
        margin-bottom: 2%;
        margin-top: 3%;
        display: flex;
      }
      #coverPagealbum img {
        width: 300px;
        height: 300px;
      }
      #coverPagealbum{
        width: 300px;
        height: 300px;
        margin-right: 3%;
      }
      #albumDisplay{
        width: 100%;
        margin: 0 1%;
      }
      #ytVideoMusicPage{
        width: 100%;
        height: 500px;
      }
  }
#albumIndoSummary h1{
  text-align: center;
  padding-bottom: 1%;
  border-bottom:1px solid rgba(255, 255, 255, 0.4);
  width: 100%;
}
#albumIndoSummary h2{
  text-align: center;
}

#albumIndoSummary .noBorder{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1500px;
}
#albumIndoSummary .link{
  color: #AF926E;
  text-decoration: underline;
}
#albumIndoSummary .link:hover{
  color: #b6a187;
}
#albumIndoSummary .noBorder p{
  border: none;
  text-align: justify;
}
  @media only screen and (max-width: 630px){
    #albumIndoSummary h1{
      padding: -10% 10% 4% 10%;
    }
    #albumIndoSummary h2{
      padding: 2%;
    }
    #musicPageTemplate {
        width: 100%;
        height: 1400px;
        margin-top: -5px;
      }
    #albumDistribution{
        width: 80%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 3% 0;
      }
      #albumInfo{
        width: 100%;
        max-height: 800px;
        margin-bottom: 2%;
        margin-top: 3%;
        display: flex;
        flex-wrap: wrap;
      }
  }

  #albumIndoSummary{
    background-color: #18171C;
    padding: 2%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #albumIndoSummary p{
    width: 80%;
    text-align: center;
    font-size: 1.1rem;
    padding: 2%;
    border-top: 1px solid #EED476;
    border-bottom: 1px solid #EED476;
  }

  @media only screen and (max-width: 400px){
    #albumIndoSummary h1{
      margin: -12% 40% 5% 40%;
    }
    #musicPageTemplate {
      width: 100%;
      height: 1600px;
      margin-top: -5px;
    }
    #albumDistribution{
        width: 80%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 3% 0;
        justify-content: center;
      }
      #coverPagealbum{
        width: 300px;
        height: 300px;
        margin-right: 3%;
        display: flex;
        justify-content: center;
      }
      #albumInfo{
        width: 100%;
        max-height: 1000px;
        margin-bottom: 2%;
        margin-top: 3%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        box-shadow: 0px 10px 10px -10px #EED476;
        border-right: none;
      }
      #albumIndoSummary{
        padding:20% 0;
      }
  }