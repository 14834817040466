#chapters{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1%;
    margin: 3%;
    background-color: #18171C;
    border-radius: 10px;
    border: 1px solid #EED476;
}


#chapters h1{
    font-size: 2.5vh;
    border-bottom: 1px solid #AD906E;
}

#chapters h1:hover{
    color: #AD906E;
}

#chapters button{
    background: none;
    border: none;
    color: white;
}