/* GENERAL */
.centerItems{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}
/* PT 0 */
#artistCoverPage{
    margin: 0;
    padding: 0;
}
#artistCoverPage img{
    width: 100%;
    min-height: 800px;
    object-fit:cover;
    margin: 0;
}
#artistCoverPage2{
    margin: 0;
    padding: 0;
    display: none;
}
#artistCoverPage2 img{
    width: 100%;
    min-height: 700px;
    object-fit:cover;
    margin: 0;
}
#artistCoverPage3{
    margin: 0;
    padding: 0;
    display: none;
}
#artistCoverPage3 img{
    width: 100%;
    min-height: 600px;
    object-fit:cover;
    margin: 0;
}
@media screen and (max-width:1000px){
    #artistCoverPage2{
        display: block;
    }
    #artistCoverPage{
        display: none;
    }
}
@media screen and (max-width:550px){
    #artistCoverPage2{
        display: none;
    }
    #artistCoverPage{
        display: none;
    }
    #artistCoverPage3{
        margin: 0;
        padding: 0;
        display: block;
    }
}
/* PT1 Counter */


/* pt2 content */
#pt2-content{
    margin: 3% 0 0 0;
    padding: 2%;
    width: 100%;
    height: 100%;
    background-color: #1C1B21;
    display: flex;
    justify-content: space-between;
}
#pt2-content h2{
    margin: 0;
    font-size: 2rem;
}
#pt2-content p{
    font-size: 1rem;
}

@media only screen and (max-width:800px){
    #pt2-content p{
        max-height: auto; /* Set the maximum height to 60px */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 12; 
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
    }
}

#pt2-content .imageContainer {
    height: 600px;
    width: 40%;
    margin: 0;
    padding: 0;
}
#pt2-content .imageContainer img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid rgba(256,256,256,0.5);
}
#pt2-content .centerItemsY{
    margin: 0 5% 0 2%;
    max-height: 700px;
    display: flex;
    align-items: center;
    width: 50%;
}
#pt2-content .postText-2{
    margin: 0;
    padding: 0;
    border-left: 1px solid white;
    padding: 2%;
}

@media only screen and (max-width: 850px){
    #pt2-content{
        background: url("logo.png") no-repeat;
        background-position: center;
        background-size: cover;

    }
    #pt2-content .imageContainer {
        display: none;
    }
    #pt2-content .imageContainer img{
       display: none;
    }
    #pt2-content .centerItemsY{
        margin: 0 4%;
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
    #pt2-content .postText-2{
        margin: 0;
        padding: 0;
        border-left: 1px solid white;
        padding: 2%;
        
    }
}

@media only screen and (max-width:500px){
    #pt2-content{
        overflow: hidden;
    }
    #pt2-content h2{
        margin: 0;
        font-size: 1.5rem;
    }
    #pt2-content p{
        font-size: 1rem;
    }
}
  
/* pt3 content */
#pt3-content{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #1C1B21;
    display: flex;
    justify-content: space-between;
}
#pt3-content h2{
    margin: 0;
    font-size: 2rem;
}
#pt3-content p{
    font-size: 1rem;
}

@media only screen and (max-width:800px){
    #pt3-content p{
        max-height: auto; /* Set the maximum height to 60px */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 12; 
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
    }
}

#pt3-content .imageContainer {
    height: 700px;
    width: 50%;
    margin: 0;
    padding: 0;
}
#pt3-content .imageContainer img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
#pt3-content .centerItemsY{
    margin: 0 0 0 4%;
    max-height: 700px;
    display: flex;
    align-items: center;
    width: 50%;
}
#pt3-content .postText-2{
    margin: 0;
    padding: 0;
    border-left: 1px solid white;
    padding: 2%;
}

@media only screen and (max-width: 850px){
    #pt3-content{
        background: url("anotherLove.png") no-repeat;
        background-position: top;
        background-size: cover;

    }
    #pt3-content .imageContainer {
        display: none;
    }
    #pt3-content .imageContainer img{
       display: none;
    }
    #pt3-content .centerItemsY{
        margin: 0 4%;
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
    #pt3-content .postText-2{
        margin: 0;
        padding: 0;
        border-left: 1px solid white;
        padding: 2%;
        
    }
}

@media only screen and (max-width:500px){
    #pt3-content{
        overflow: hidden;
    }
    #pt3-content h2{
        margin: 0;
        font-size: 1.5rem;
    }
    #pt3-content p{
        font-size: 1rem;
    }
}

/* MAINCURRENTVIDEO */
#mainCurrentVideo{
    width: 100%;
    margin: 0;
    position: relative;
    display: inline-block;
    overflow: hidden;
}
#mainCurrentVideo img{
    width: 100%;
    max-height: 600px;
    min-height: 400px;
    filter: blur(5px);
}
.overImage{
    position: absolute;
    top: 50%; 
    left: 0;
    right: 0;
    transform: translate(0, -50%); 
    padding: 10px; 
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin: 0 10%;
}
.overImage h2, .overImage h3{
    width: 100%;
    text-align: center;
    margin: 0;
}
.overImage h2{
    font-size: 3rem;
}
.overImage h3{
    font-size: 4rem;
}
@media only screen and (max-width:900px){
    .overImage{
        margin: 0;
    }
}

@media only screen and (max-width:750px){
    .overImage h2{
        font-size: 2rem;
    }
    .overImage h3{
        font-size: 3rem;
    }
}
@media only screen and (max-width:600px){
    .overImage h2{
        font-size: 1.5rem;
    }
    .overImage h3{
        font-size: 2rem;
    }
}