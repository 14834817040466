/* IMPORTS */
@import url('https://fonts.googleapis.com/css2?family=Nova+Square&family=Playfair+Display&family=Work+Sans:ital@0;1&display=swap');
/* SCROLL BAR */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: rgba(54, 54, 54, 0.7);
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #A55B03, #EED476, #AD906E, #A55B03, #AD906E, #EED476);
}

:root{
  --size1:3rem;
  --size2: 1rem;
}

/* UNIVERSAL STYLING */

*{
  /* border: 1px solid white; */
  scroll-behavior: smooth;
  box-sizing: border-box;
}
html{
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
}
body{
  background-color: black;
    font-family:'Times New Roman', Times, serif;
    font-size: 16px;
    color: rgb(255, 255, 255);
    margin: 0;
    padding: 0;
    overflow-x: hidden !important;
}
a{
  color: aliceblue;
  text-decoration: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
button{
  cursor: pointer;
}
div{
  margin: 0;
  padding: 0;
}
h1,h2,h3,p,span{
    overflow: hidden;
    font-family: 'Zilla Slab';
    font-family: 'Times New Roman', Times, serif;
}
p, span {
  font-family: 'Work Sans', sans-serif;
}

/* GENARAL CLASSES */
.hidden{
  display: none;
}
.siteTitle{
  font-family: specialFont, 'Times New Roman', serif;
}
.active{
  color: #AD906E;
  border-bottom: 1px solid #AD906E;
}
.centerAll{
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
}

#latestVideo{
  height: 700px;
  width: 100%;
}
@media screen and (max-width:1000px){
  #latestVideo{
    height: 500px;
    width: 100%;
  }
}

/* BUTTONS */
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  color: #0f1923;
  cursor: pointer;
  position: relative;
  padding: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  transition: all .15s ease;
}

.button::before,
.button::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: calc(50% - 5px);
  border: 1px solid #AD906E;
  transition: all .15s ease;
}

.button::before {
  top: 0;
  border-bottom-width: 0;
}

.button::after {
  bottom: 0;
  border-top-width: 0;
}

.button:active,
.button:focus {
  outline: none;
}

.button:active::before,
.button:active::after {
  right: 3px;
  left: 3px;
}

.button:active::before {
  top: 3px;
}

.button:active::after {
  bottom: 3px;
}

.button_lg {
  position: relative;
  display: block;
  padding: 10px 20px;
  color: #fff;
  background-color: #0f1923;
  margin: 5px;
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 1px transparent;
  font-family: specialFont, 'Times New Roman', serif;
}

.button_lg::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  background-color: #0f1923;
}

.button_lg::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4px;
  height: 4px;
  background-color: #0f1923;
  transition: all .2s ease;
}

.button_sl {
  display: block;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: -8px;
  width: 0;
  background-color: #AD906E;
  transform: skew(-15deg);
  transition: all .2s ease;
}

.button_text {
  position: relative;
}

.button:hover {
  color: #0f1923;
}

.button:hover .button_sl {
  width: calc(100% + 15px);
}

/* SCREEN SIZES */
@media only screen and (max-width: 1000px){
  header{
    margin: 4% 0 0 0;
}
#content-container{
  margin: 0;
  padding: 0;
}
}
#content-container{
  background-image: url("backgroundStyle.png");
}

#nav-phone{
  margin: 1% 0;
  background-color: #1C1B21;
  display: none;
}

#ul-phone-nav{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
#ul-phone-nav li{
  margin: 5%;
  padding: 5%;
}
#socialMedia{
  display: block;
}

@media only screen and (max-width: 950px){
  header{
    justify-content: space-around;
  }
  #navigation{
    display: none;
  }
  #actions{
    display: none;
  }
  #nav-phone{
    display: block;
    opacity: 1;
  }

}
@media only screen and (max-width: 700px){
  header{
    justify-content: space-around;
  }
  #navigation{
    display: none;
  }
  .action{
    display: none;
  }
  #actions{
    display: block;
  }
  #socialMedia{
    display: none;
  }

  #nav-phone {
  display: none;
  opacity: 1;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
}

#nav-phone.active {
  opacity: 1;
  height: auto;
  transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
}

header img{
  border-radius: 50%;
  max-height: 50px;
  max-width: 50px;
  margin: 0;
  padding: 0;
  border: 1px solid whitesmoke;
}
header img:hover{
  transition: 800ms;
  max-height: 60px;
  max-width: 70px;
}
#artistTitle{
  font-size: 2.8rem;
}
/* EFFECTS */
#sidebar-trigger, #media-trigger{
  font-size: var(--size2);
  margin-right: 5%;
  margin-left: 2%;
  z-index: 100;
}
#sidebar-trigger:hover, #media-trigger:hover{
  color:rgba(255, 255, 255, 0.95);
  opacity: 1;
  transform: scale(1.6);
  transition: all 300ms;
}

}


@media only screen and (max-width: 100px){
  body *{
    overflow: hidden;
  }
}

/* LEFT ABS */
/* LEFT ABSOLUTE */
#goToTop {
  position:fixed;
  top:3em;
  left:0;
  right:0;
  width: 20px;
  height: 20px;
  padding:30% 0 0 0;
  margin-left: 6%;
  margin-right: 0;
  margin: 0;
  z-index: 5;
}
#divToTop{
  top:3em;
  left:0;
  right:0;
  width: 10px;
  height: 0.000000001px;
  text-align:left;
  padding: 0 0 0 20px;
  margin: 0;
  z-index: 5;
}
#goToTop a{
  width: 20px;
}
#media-sticky{
  margin-left: 2%;
  position: sticky;
  width: 40px;
  height: 40px;
  background: linear-gradient(45deg, #EED476, #AD906E, #A55B03);
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
#goToTop i {
  font-size: 20px;
  z-index: 1;
}

#goToTop i:hover {
  z-index: 1;
  transform: scale(1.5);
}
@media only screen and (max-width:700px){
  #goToTop{
    display: none;
  }
}

/* SCREEN  PHONE*/
@media only screen and (max-width: 700px){
  
  .basr-social-share {
    position:fixed;
    top:3em;
    left:1;
    right:0;
    width: 60%;
    display: inline;
    text-align:right;
    padding:5% 8% 0 0;
    margin:10% 11%;
    z-index: 3;
  }
}

@media only screen and (max-width: 280px){
  .basr-social-share {

    padding:5% 15% 0 0;

  }
}

/* COVERPAGES */
#coverPage{
    width: 100%;
    margin: 0;
    position: relative;
    display: inline-block;
    overflow: hidden;
}
.copageContainer{
  width: 90%;
  margin: 2% 5%;
  padding: 0;
  max-height: 300px;
}
.copageContainer img{
  width: 100%;
  max-height: 300px;
  min-height: 200px;
  object-fit: cover;
  margin: 0;
  padding: 0;
  filter: 0;
}

#coverPage h1{
  position: absolute;
    top: 50%; 
    left: 0;
    right: 0;
    transform: translate(0, -50%); 
    padding: 10px; 
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin: 0 10%;
    font-size: 8vh;
    text-decoration: underline;
}
@media only screen and (max-width: 700px){
  .copageContainer{
    width: 100% !important;
    margin: 2% 0;
    padding: 0;
    max-height: 300px;
  }
}