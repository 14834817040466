/* SUBSCRIPTION */

.subscribe{
    background-color: #18171C;
    padding: 2% 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
  
  @media only screen and (max-width: 400px) {
    .subscribe{
      padding: 2% 0;
    }
  }
  
  .subscribe p{
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    border-bottom: 1px solid #EED476;
    padding-bottom: 10px;
  }
  
  .preSave, .mailing{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
  }
  
  .btn, .btn2, .btn3{
    margin: 2%;
  }
  .btn {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #40B3A2;
    min-width: 300px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 16px 20px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
  }
  
  .btn:hover, .btn2:hover, .btn3:hover, .btn4:hover {
    opacity: .90;
  }
  
  .btn .animation, .btn2 .animation, .btn3 .animation, .btn4 .animation {
    border-radius: 100%;
    animation: ripple 0.6s linear infinite;
  }
  
  .btn2 {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #b34040;
    min-width: 300px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 16px 20px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
  }
  .btn3 {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #4048b3;
    min-width: 300px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 16px 20px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
  }
  .btn4 {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #d93131;
    width: 100%;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 16px 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
  }
  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
    }
  
    100% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
    }
  }
  
  .fix-btn{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

/* MAILING */
.input-container {
  position: relative;
  display: flex;
  height: 2.8rem;
  width: 100%;
  min-width: 300px;
  max-width: 350px;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 20px 20px 30px rgba(94, 130, 178, 0.05);
  overflow: hidden;
}
#formmail{
  position: relative;
  display: flex;
  height: 2.8rem;
  width: 100%;
  min-width: 300px;
  max-width: 350px;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 20px 20px 30px rgba(94, 130, 178, 0.05);
}
@media only screen and (max-width: 350px){
  .input-container{
    display: flex;
    flex-wrap: wrap !important;
  }
}

.input-container input {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border: 1px solid  #AD906E;
  background-color: transparent;
  padding: 0.625rem 70px 0.625rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: rgb(255, 255, 255);
  outline: none;
  transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);
}

.input-container input:focus {
  border: 1px solid rgb(255, 255, 255);
}

.invite-btn {
  position: absolute;
  width: 100px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  z-index: 10;
  border-radius: 4px;
  background-color: #AD906E;
  color: #fff;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  transition: .6s ease;
}

.invite-btn:hover {
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: 8px;
}

.input-container input:placeholder-shown ~ .invite-btn {
  pointer-events: none;
  background-color: rgb(85, 85, 85);
  opacity: 0.5;
}