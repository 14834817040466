

#musicPageTemplate {
    width: 100%;
    height: 850px;
    transition: height 0.5s ease; 
    margin-top: -5px;
  }
  #musicPageTemplateExpanded {
    width: 100%;
    height: 1600px;
    transition: height 0.5s ease; 
    margin-top: -5px;
  }
  #lyricSpaceContent{
    padding: 1%;
    margin-top: 2%;
  }
  #lyricsContent {
    transition: height 0.5s ease; 
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    padding: 2%;
    min-height:200px;
    max-height: 750px;
    overflow-y: scroll;
  }
  
  .contentmusicPageTemplate {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 300ms;
    box-shadow: 0px 0px 10px 1px #000000ee;
    border-radius: 5px;
  }
  
 .backcontentmusicPageTemplate {
    background-color: #18171C;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .backcontentmusicPageTemplate {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .backcontentmusicPageTemplate::before {
    position: absolute;
    content: ' ';
    display: block;
    width: 100%;
    height: 160%;
    background: linear-gradient(90deg, transparent,  #EED476, #AD906E, #A55B03, transparent);
    animation: rotation_481 7000ms infinite linear;
  }
  
  .Dircontent {
    position: absolute;
    width: 99%;
    height: 99%;
    background-color: #18171C;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    display: flex;
    align-items: space-between;
  }
  
  @keyframes rotation_481 {
    0% {
      transform: rotateZ(0deg);
    }
  
    0% {
      transform: rotateZ(360deg);
    }
  }

  #songDistribution{
    width: 80%;
    height: 100%;
    display: flex;
    margin: 3% 0;
  }
  #songInfo{
    width: 30%;
    max-height: 750px;
    border-right: 1px solid #AD906E;
    margin: 1%;
    padding: 2%;
    border-bottom: 1px solid #AD906E;
    box-shadow: 10px 10px 10px -10px #EED476;
  }
  #musicPageMedia{
    display: flex;
    width: 150px;
    justify-content: space-evenly;
  }

  #musicPageMedia a{
    font-size: 1.2rem;
  }
  #coverPageSong img {
    width: 300px;
    height: 300px;
  }
  #songInfo h1{
    border-bottom: 1px solid rgba(161, 161, 161,0.3);
    padding-bottom: 2%;
  }
  #coverPageSong{
    width: 100%;
    height: 300px;
  }
  #songDisplay{
    width: 70%;
    margin: 0 1%;
  }
  #ytVideoMusicPage{
    width: 100%;
    height: 600px;
  }

  @media only screen and (max-width: 1400px){
    #musicPageTemplate {
        width: 100%;
        height: 1250px;
        margin-top: -5px;
      }
      #musicPageTemplateExpanded {
        width: 100%;
        height: 2000px;
        margin-top: -5px;
      }
    #songDistribution{
        width: 80%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 3% 0;
      }
      #songInfo{
        width: 100%;
        max-height: 400px;
        margin-bottom: 2%;
        margin-top: 3%;
        display: flex;
      }
      #coverPageSong img {
        width: 300px;
        height: 300px;
      }
      #coverPageSong{
        width: 300px;
        height: 300px;
        margin-right: 3%;
      }
      #songDisplay{
        width: 100%;
        margin: 0 1%;
      }
      #ytVideoMusicPage{
        width: 100%;
        height: 500px;
      }
  }

  @media only screen and (max-width: 630px){
    #musicPageTemplate {
        width: 100%;
        height: 1350px;
        margin-top: -5px;
      }
      #musicPageTemplateExpanded {
        width: 100%;
        height: 2100px;
        transition: height 0.5s ease; 
        margin-top: -5px;
      }
    #songDistribution{
        width: 80%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 3% 0;
      }
      #songInfo{
        width: 100%;
        max-height: 800px;
        margin-bottom: 2%;
        margin-top: 3%;
        display: flex;
        flex-wrap: wrap;
      }
  }
  @media only screen and (max-width: 400px){
    #songDistribution{
        width: 80%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 3% 0;
        justify-content: center;
      }
      #songInfo{
        width: 100%;
        max-height: 800px;
        margin-bottom: 2%;
        margin-top: 3%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        box-shadow: 0px 10px 10px -10px #EED476;
        border-right: none;
      }
  }