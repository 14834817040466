/* FOOTER */
footer{
    display: flex;
    flex-wrap: wrap;
    background-color: #18171C;
    padding: 1%;
    font-size: 0.8rem;
    margin: 0;
    align-content: center;
    justify-content: space-around;
  }
  footer h3{
    margin: 2%;
  }
  
  footer a{
    color: #ac9ce3;
  }
  
  #footerMedia{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    width: 40%;
    margin: 0;
    padding: 0;
  }
  
  #footerMedia *{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: #cccccc;
  }
  
  #fotter a i{
    font-size: 10rem;
    color: #cccccc;
  }
  @media only screen and (max-width: 750px){
    footer{
      padding: 2% 0 4% 0;
    }
  }
  @media only screen and (max-width: 300px) {
    #footerMedia{
      width: 80%;
    }
  }