.centerFix{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.7);
}
.info{
    text-decoration: underline;
}
.info:hover{
    font-size: 18px;
}
.testMusicPage{
    width: 100%;
    font-size: 1.5rem;
    border-bottom: 1px solid #EED476;
    text-align: center;
}
.testMusicPage2{
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
}

.spotify-player iframe{
    height: 400px;
    width: 600px;
    padding: 0;
    margin:0;
}
#albumMusic{
    display: flex;
    justify-content: center;
    align-items: center;
}
#albumMusic .music-square{
    height: 400px;
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

#albumMusic .overlay p{
    width: 100%;
    margin: 0;
    padding: 0;
}
/* MUSIC-SONGS */
.music-grid {
    display: flex;
    flex-wrap: wrap;
    max-width: 1500px;
    gap: 20px;
    justify-content: center;
    padding: 5%;
    padding-top: 2%;
  }
  
  .music-square {
    position: relative;
    overflow: hidden;
    width: 300px;
    height: 300px;
  }

  @media only screen and (max-width:1300px){
    .music-square {
        position: relative;
        overflow: hidden;
        width: 350px;
        height: 350px;
      }
}
@media only screen and (max-width:1130px){
    .spotify-player{
        width: 80%;
    }
    .spotify-player iframe{
        height: 360px;
        width: 100%;
        padding: 0;
        margin:0;
    }
    #albumMusic .music-square{
        height: 350px;
        width: 350px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}
@media only screen and (max-width:830px){
    .spotify-player{
        width: 70%;
    }
}
@media only screen and (max-width:560px){
    .spotify-player{
        width: 100%;
    }
}
@media only screen and (max-width:350px){
    .spotify-player{
        width: 80%;
    }
}
  
  .music-square img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(80%);
    transition: filter 0.3s ease-in-out;
  }
  
  .music-square:hover img {
    filter: brightness(20%);
  }
  
  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .music-square:hover .overlay {
    opacity: 1;
  }
  
  .overlay p, .overlay h1 {
    margin: 5px 0;
  }
  .overlay h1{
    font-size: 1.5rem;
  }
  
  .overlay a {
    display: inline-block;
    background-color: #333;
    padding: 5px 10px;
    text-decoration: none;
    color: white;
    border-radius: 5px;
    margin-top: 10px;
  }

  .loadButContent {
    width: 140px;
    height: 56px;
    overflow: hidden;
    border: none;
    color: #ffffff;
    opacity: 0.8;
    background: none;
    position: relative;
    padding-bottom: 2em;
    cursor: pointer;
    margin-top: 2%;
  }
  .loadButContent:hover{
    opacity: 1;
  }
  
  .loadButContent > div,
  .loadButContent > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
  }
  
  .loadButContent:before {
    content: "";
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: scaleX(0);
    transform-origin: bottom right;
    background: currentColor;
    transition: transform 0.25s ease-out;
  }
  
  .loadButContent:hover:before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  .loadButContent .clone > *,
  .loadButContent .text > * {
    opacity: 1;
    font-size: 1.3rem;
    transition: 0.2s;
    margin-left: 4px;
  }
  
  .loadButContent .clone > * {
    transform: translateY(60px);
  }
  
  .loadButContent:hover .clone > * {
    opacity: 1;
    transform: translateY(0px);
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }
  
  .loadButContent:hover .text > * {
    opacity: 1;
    transform: translateY(-60px);
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }
  
  .loadButContent:hover .clone > :nth-child(1) {
    transition-delay: 0.15s;
  }
  
  .loadButContent:hover .clone > :nth-child(2) {
    transition-delay: 0.2s;
  }
  
  .loadButContent:hover .clone > :nth-child(3) {
    transition-delay: 0.25s;
  }
  
  .loadButContent:hover .clone > :nth-child(4) {
    transition-delay: 0.3s;
  }
