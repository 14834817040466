/* Navigation */
header{
    background-color: black;
    display: flex;
    padding: 0;
    margin: 1% 4% 0 4%;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    border-bottom: 1px solid #B0946C;
    padding-bottom: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 10px 10px -10px #EED476;
}
header *{
    font-family: 'Times New Roman', Times, serif;
}
header img{
    border-radius: 50%;
    max-height: 60px;
    max-width: 60px;
    margin: 0;
    padding: 0;
    border: 1px solid #EED476;
}
header img:hover{
    transition: 800ms;
    max-height: 70px;
    max-width: 70px;
}
.site-title{
    display: flex;
    padding: auto;
    width: auto;
    display: flex;
    margin: 0;
    padding: 0;
}
#actions{
    display: none;
}
#navigation{
    height: 100%;
    width: 50%;
    margin: auto;
}
nav{
    margin: 0;
    padding: 0;
    width: 100%;
}
nav ul{
    display: flex;
    width: 100%;
    margin: 0%;
    padding: 0;
    justify-content: space-around;
}
nav a, nav li{
    text-decoration: none;
    margin: 0 5%;
    font-size: var(--size2);
    list-style-type: none;
}
nav li:hover{
    transition: 500ms;
    font-size: 1.2rem;
    border-bottom: 1px solid #B0946C;
    color: #B2966E;
}
#artistTitle,#artistTitle::after {
    padding: 10p  x 30px;
    margin: 0 0 0 10px;
    font-size: var(--size1);
    border: none;
    border-radius: 5px;
    background-color: transparent;
    position: relative;
    background: -webkit-linear-gradient(60deg,#EED476, #AD906E, #A55B03);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  #artistTitle::after {
    --move1: inset(50% 50% 50% 50%);
    --move2: inset(31% 0 40% 0);
    --move3: inset(39% 0 15% 0);
    --move4: inset(45% 0 40% 0);
    --move5: inset(45% 0 6% 0);
    --move6: inset(14% 0 61% 0);
    clip-path: var(--move1);
    content: 'GLITCH';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
  }
  
  #artistTitle:hover::after {
    animation: glitch_4011 1s;
    text-shadow: 10 10px 10px black;
    animation-timing-function: steps(2, end);
    text-shadow: -3px -3px 0px #AD906E, 3px 3px 0px #ad906ea0;
    background-color: transparent;
    border: 3px solid rgb(0, 0, 0);
  }
  
  #artistTitle:hover {
    text-shadow: -1px -1px 0px #AD906E, 1px 1px 0px #EED476;
    background-color: transparent;
    box-shadow: 0px 10px 10px -10px #EED476;
  }
  
  @keyframes glitch_4011 {
    0% {
      clip-path: var(--move1);
      transform: translate(0px,-10px);
    }
  
    10% {
      clip-path: var(--move2);
      transform: translate(-10px,10px);
    }
  
    20% {
      clip-path: var(--move3);
      transform: translate(10px,0px);
    }
  
    30% {
      clip-path: var(--move4);
      transform: translate(-10px,10px);
    }
  
    40% {
      clip-path: var(--move5);
      transform: translate(10px,-10px);
    }
  
    50% {
      clip-path: var(--move6);
      transform: translate(-10px,10px);
    }
  
    60% {
      clip-path: var(--move1);
      transform: translate(10px,-10px);
    }
  
    70% {
      clip-path: var(--move3);
      transform: translate(-10px,10px);
    }
  
    80% {
      clip-path: var(--move2);
      transform: translate(10px,-10px);
    }
  
    90% {
      clip-path: var(--move4);
      transform: translate(-10px,10px);
    }
  
    100% {
      clip-path: var(--move1);
      transform: translate(0);
    }
  }
  
  