/* TEXT ACTIONS */
#textActions{
    padding: 0;
    margin: 3% 0;
    display: flex;
    align-content: center;
  }
  #text-carousel{
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  #textActions h3{
    text-align: center;
    font-weight: 100;
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
  }