.countdown-timer-container {
    width: 100%;
    background-color: transparent;
    color: white;
    padding: 20px;
    text-align: center;
  }
  .countdown-timer-container h1{
    font-size: 5vh;
  }
  .countdown-timer-container h2{
    font-size: 4vh;
  }
  .countdown-timer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: 2em;
    border: 2px solid #EED476;
    margin: 1%;
    padding: 1%;
  }
  
  .countdown-item {
    flex: 1;
    margin: 0 10px; 
  }
  
  .countdown-value {
    font-weight: bold;
    font-size: 2.5em; 
  }
  
  .countdown-label {
    text-transform: uppercase;
    font-size: 0.8em; 
  }

  .cardT {
    width: 100%;
    height: 500px;
    min-height: 500px;
    max-height: 800px;
    margin-top: -5px;
  }
  
  .content {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 300ms;
    box-shadow: 0px 0px 10px 1px #000000ee;
    border-radius: 5px;
  }
  
 .back {
    background-color: #18171C;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .back {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .back::before {
    position: absolute;
    content: ' ';
    display: block;
    width: 100%;
    height: 160%;
    background: linear-gradient(90deg, transparent,  #EED476, #AD906E, #A55B03, transparent);
    animation: rotation_481 7000ms infinite linear;
  }
  
  .back-content {
    position: absolute;
    width: 99%;
    height: 99%;
    background-color: #18171C;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    display: flex;
    align-items: space-between;
  }
  
  @keyframes rotation_481 {
    0% {
      transform: rotateZ(0deg);
    }
  
    0% {
      transform: rotateZ(360deg);
    }
  }
  #info a {
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    border: 2px solid #EED476;
    color: #fefefe;
    text-decoration: none;
    font-weight: 600;
    font-size: 20px;
  }
  
  #info a::before {
    content: '';
    position: absolute;
    top: 6px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% - 12px);
    background-color: #18171C;
    transition: 0.1s ease-in-out;
    transform: scaleY(1);
  }
  
  #info a:hover::before {
    transform: scaleY(0);
  }
  
  #info a::after {
    content: '';
    position: absolute;
    left: 6px;
    top: -2px;
    height: calc(100% + 4px);
    width: calc(100% - 12px);
    background-color: #18171C;
    transition: 0.3s ease-in-out;
    transform: scaleX(1);
    transition-delay: 0.2s;
  }
  
  #info a:hover::after {
    transform: scaleX(0);
  }
  
  #info a span {
    position: relative;
    z-index: 3;
  }
  
  #info button {
    background-color: none;
    text-decoration: none;
    background-color: transparent;
    border: none;
  }

  @media only screen and (max-width: 768px){
    .countdown-timer-container h1{
        font-size: 3vh;
      }
      .countdown-timer-container h2{
        font-size: 2vh;
      }
      .countdown-timer {
        font-size: 1em;
      }
  }