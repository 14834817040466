#visualsPage{
    width: 90%;
}
#visualsPage h1{
    text-align: center;
    margin-bottom: 2%;
    padding-bottom: 1%;
    border-bottom: 1px solid #AD906E;
}
#generalVisuals{
    display: flex;
    width: 100%;
    padding: 0 5%;
    justify-content: space-evenly;
    align-items: center;
}
.visualsAction{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #AD906E;
    background-color: rgba(0,0,0,1);
    border-radius: 50px;
    margin: 0 2% 10% 2%;
}
.visualsAction:hover{
    border: 1px solid white;
    border-radius: 50px;
}
.visualsAction img {
    width: 500px;
    height: 800px;
    object-fit: cover;
    position: relative;
    opacity: 0.5;
    border-radius: 50px;
}

.visualsAction .colButVis{
    position: absolute;
    z-index: 1;
}
.visualsActionExpanded:hover{
    border: 1px solid white;
    border-radius: 50px;
}
.visualsActionExpanded{
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #AD906E;
    background-color: rgba(0,0,0,1);
    border-radius: 50px;
    margin: 0 2% 3% 2%;
}
.visualsActionExpanded img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    position: relative;
    opacity: 0.5;
    border-radius: 50px;
}
.visualsActionExpanded .colButVis{
    position: absolute;
    z-index: 1;
}

@media only screen and (max-width:1300px){
    .visualsAction img {
        max-width: 400px;
    }
    .visualsActionExpanded{
        width: 1000px;
    }
}
@media only screen and (max-width:1000px){
    #generalVisuals{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 5%;
        justify-content: space-evenly;
        align-items: center;
    }
    .visualsAction{
        width: 100%;
    }
    .visualsAction:hover{
        border: 1px solid white;
        border-radius: 50px;
    }
    .visualsAction img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        position: relative;
        opacity: 0.5;
        border-radius: 50px;
    }
    .visualsActionExpanded{
        width: 700px;
    }
}
@media only screen and (max-width:700px){
    #visualsPage h1{
        font-size: 1.5rem;
    }
    .visualsActionExpanded{
        width: 400px;
    }
}
@media only screen and (max-width:400px){
    #visualsPage h1{
        font-size: 1.5rem;
    }
    .visualsAction img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        position: relative;
        opacity: 0.5;
        border-radius: 50px;
    }
    .visualsActionExpanded{
        width: 300px;
    }
}

@media only screen and (max-width:200px){
    .visualsAction .colButVis *{
        overflow: hidden !important;
    }
}





/* ------------------------------------------------------------------------- */


#imgAbout{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #AD906E;
    box-shadow: 0px 10px 10px -10px #EED476;
}


#project{
    width: 100%;
    padding: 2%;
    margin-top: 2%;
}
.textSectionAbout{
    display: flex;
    justify-content:space-around;
    align-items: center;
    margin-bottom: 2%;
}
.textAbout{
    max-width: 700px;
    padding: 2%;
    border-left: 1px solid #AD906E;
    background-color: rgba(0,0,0,0.6);
}
.textAbout p{
    font-size: 1.2rem;
    padding: 1%;
    text-align: justify;
}

@media screen and (max-width: 1000px){
    .textSectionAbout{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 2%;
    }
    #imgAbout{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #AD906E;
        border-bottom: 1px solid #AD906E;
        box-shadow: 0px 10px 10px -10px #EED476;
    }
    .textAbout{
        width: 100%;
        padding: 2%;
        border-left: 1px solid #AD906E;
        background-color: rgba(0,0,0,0.6);
        margin-bottom: 2%;
    }

    .imgAbout, .textSectionAbout .desactive1{
        margin-bottom: 3%;
    }
    .desactive2{
        display: none;
    }
    .desactive1{
        display: block;
        margin-bottom: 1%;
    }
}
@media only screen and (max-width: 700px) {
    #imgAbout h2{
        font-size: 1.5rem;
        margin: 5%;
    }
    .textAbout h1{
        font-size: 1.8rem;
    }
    .textAbout p{
        font-size: 1rem;
    }

}

#arrows{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3% 3% 3%;
    margin-top: -2%;
    background-color: rgba(0,0,0,0.6);
}